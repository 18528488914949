import React, { useState, useEffect, useRef, useMemo, useCallback, useContext } from "react";
import { Container, Spinner } from "reactstrap";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import PageDisplay from "../Base/PageDisplay/PageDisplay";
import { FixtureDetailsContext } from "../../Config/FixtureDetails";
import { StaffRoles } from "../../Config/StaffRoles";
import { getValidationObject } from "../Base/Utilities/EntityUtilities";

import FreshChat from "../ChatWidget/FreshChat";

import { Team } from "./Team";
import TeamSetupControls from "./TeamSetupControls";
import "./TeamSetup.scss";
import { sendPlayer } from "../Base/Utilities/ManagePlayers";

const buildTeamManagerSyncStorageKey = (fixtureId, entityId) => {
  return `${fixtureId}-${entityId}-tm-sync`;
};

const TeamSetup = ({ match, updateState, currentState, history, title, intl }) => {
  const [isReadyToShow, setIsReadyToShow] = useState(false);
  const [teamsData, setTeamsData] = useState([]);
  const [validationInfo, setValidationInfo] = useState({});
  const { formatMessage } = intl;
  const pageRef = useRef();
  const { competitors, errorMsg, hasLoaded, fixtureProfile, organization, forceRefresh, sport } =
    useContext(FixtureDetailsContext);

  useEffect(() => {
    const storedTeamsData = localStorage.getItem(match.params.fixtureId + "-teams");
    if (storedTeamsData) {
      setTeamsData(JSON.parse(storedTeamsData));
    }
  }, [match.params.fixtureId]);

  const buildNewTeamsData = () => {
    if (competitors) {
      const locallyStoredTeams = JSON.parse(localStorage.getItem(match.params.fixtureId + "-teams"));
      const newTeamsData = Array(competitors.length).fill(null);
      competitors.forEach((competitor) => {
        const team = {};
        const teamIndex = competitor.isHome ? 0 : 1;
        const savedEntityColor = localStorage.getItem(`${competitor.entityId}-color`);
        if (savedEntityColor !== null) {
          team.primaryColor = savedEntityColor;
        } else {
          team.primaryColor = teamIndex === 0 ? "#b71c1c" : "#0D47A1";
        }
        team.entityId = competitor.entityId;
        team.nameFullLocal = competitor.nameFullLocal;
        team.fullNameLatin = competitor.nameFullLocal;

        let storedTeam = null;
        if (currentState?.mqtt?.entities) {
          storedTeam = currentState.mqtt.entities.find((team) => competitor.entityId === team.entityId);
          if (storedTeam?.roles) {
            const newStaff = {};
            Object.entries(storedTeam.roles).forEach(([roleName, roleMembers]) => {
              Object.entries(roleMembers).forEach(([staffPersonIn, staffPersonData]) => {
                newStaff[staffPersonIn] = {
                  ...staffPersonData,
                  personId: staffPersonIn,
                  role: roleName,
                };
              });
            });
            storedTeam.staff = newStaff;
          }
        }

        let userStoredTeam = null;
        if (locallyStoredTeams) {
          userStoredTeam = locallyStoredTeams.find((t) => t.entityId === competitor.entityId);
        }

        const rosters =
          competitor.rosters?.fixture?.length && sport !== "handball"
            ? competitor.rosters.fixture
            : competitor.rosters.season;

        const allActiveBibs =
          storedTeam?.persons &&
          Object.keys(storedTeam.persons)
            // filter out persons that are removed from the roster
            .filter((personId) => rosters.some((rosterPerson) => rosterPerson.personId === personId))
            .map((personId) => storedTeam.persons[personId])
            .map((person) => person.bib);

        const activeBibSet = new Set(allActiveBibs);
        const duplicateBibs = allActiveBibs
          ? allActiveBibs.filter((item) => {
              if (activeBibSet.has(item)) {
                activeBibSet.delete(item);
                return undefined;
              } else {
                return item;
              }
            })
          : [];

        team.persons = [];
        rosters.forEach((personSrc) => {
          const storedPerson = storedTeam?.persons?.[personSrc.personId];
          const userStoredPerson = userStoredTeam?.persons.find((p) => p.personId === personSrc.personId);
          const person = storedPerson ?? userStoredPerson ?? personSrc;
          const isDuplicate = duplicateBibs.indexOf(person.bib) > -1;
          const isPlaying = person.playing ?? !!storedPerson;
          const isStarter = person.starter ?? (isPlaying && !!person.starter);

          const getDesiredPlayingState = () => {
            if (userStoredPerson?.desiredPlayingState === undefined) {
              return undefined;
            }

            // still waiting for the valid persons message
            if (userStoredPerson?.desiredPlayingState !== isPlaying) {
              return userStoredPerson.desiredPlayingState;
            }

            // update happened successfully, clearing state
            if (userStoredPerson?.desiredPlayingState === isPlaying) {
              return undefined;
            }
          };

          team.persons.push({
            personId: personSrc.personId,
            bib: person.bib || "",
            nameFullLocal: person.nameFullLocal || person.name,
            position: person.position,
            starter: isStarter,
            playing: isPlaying,
            captain: !!person.captain,
            confirmed: !!storedPerson,
            duplicate: isDuplicate && isPlaying,
            desiredPlayingState: getDesiredPlayingState(),
          });
        });

        team.staff = [];

        competitor.roles.season.forEach((personSrc) => {
          const storedPerson = storedTeam?.staff?.[personSrc.personId];
          const userStoredPerson = userStoredTeam?.staff.find((p) => p.personId === personSrc.personId);
          const person = storedPerson ?? userStoredPerson ?? personSrc;

          team.staff.push({
            personId: person.personId,
            name: person.nameFullLocal || person.name,
            role: person.role,
            active: !!storedPerson,
            number: person.number,
            confirmed: !!storedPerson,
          });
        });

        team.staffState = {};
        // if there's a period that means this fixture has been started and the staff has been confirmed
        // no need to populate with some defaults
        const hasMatchStarted = !!currentState.period || teamsData.length > 0;
        if (userStoredTeam?.staffState) {
          team.staffState = { ...userStoredTeam?.staffState };
        } else {
          const coaches = team.staff.filter((person) => person.role === StaffRoles.coach.role);
          const activeCoach = coaches.find((person) => person.active);
          const coachPerson = activeCoach ?? (hasMatchStarted ? false : coaches[0]);
          const headCoaches = team.staff.filter((person) => person.role === StaffRoles.coach_head.role);
          const activeHeadCoach = headCoaches.find((person) => person.active);
          const headCoachPerson = activeHeadCoach ?? (hasMatchStarted ? false : headCoaches[0]);
          const assistantCoaches = team.staff.filter((person) => person.role === StaffRoles.assistantCoach1.role);
          const activeAssistantCoaches = assistantCoaches.filter((person) => person.active);
          const activeAssistantCoach1 = activeAssistantCoaches.find((person) => person.number === 1);
          const assistantCoach1 = activeAssistantCoach1 ?? (hasMatchStarted ? false : assistantCoaches[0]);
          const activeAssistantCoach2 = activeAssistantCoaches.find((person) => person.number === 2);
          const assistantCoach2 = activeAssistantCoach2 ?? (hasMatchStarted ? false : assistantCoaches[1]);
          const mainManagers = team.staff.filter((person) => person.role === StaffRoles.manager.role);
          const activeManager = mainManagers.find((person) => person.active);
          const managerPerson = activeManager ?? (hasMatchStarted ? false : mainManagers[0]);
          const physiotherapists = team.staff.filter((person) => person.role === StaffRoles.physiotherapist.role);
          const activePhysiotherapist = physiotherapists.find((person) => person.active);
          const physiotherapistPerson = activePhysiotherapist ?? (hasMatchStarted ? false : physiotherapists[0]);
          const doctors = team.staff.filter((person) => person.role === StaffRoles.doctor.role);
          const activeDoctor = doctors.find((person) => person.active);
          const doctorPerson = activeDoctor ?? (hasMatchStarted ? false : doctors[0]);
          const others = team.staff.filter((person) => StaffRoles.other.roles.includes(person.role));
          const activeOther = others.find((person) => person.active);
          const otherPerson = activeOther ?? (hasMatchStarted ? false : others[0]);

          if (sport === "basketball") {
            team.staffState = {
              coach: coachPerson
                ? {
                    personId: coachPerson.personId,
                    active: coachPerson.active,
                  }
                : { personId: "", active: false },
              assistantCoach1: assistantCoach1
                ? {
                    personId: assistantCoach1.personId,
                    active: assistantCoach1.active,
                  }
                : { personId: "", active: false },
              assistantCoach2: assistantCoach2
                ? {
                    personId: assistantCoach2.personId,
                    active: assistantCoach2.active,
                  }
                : { personId: "", active: false },
            };
          } else if (sport === "hockey") {
            team.staffState = {
              coach_head: headCoachPerson
                ? {
                    personId: headCoachPerson.personId,
                    active: headCoachPerson.active,
                  }
                : { personId: "", active: false },
              coach_assistant: assistantCoach1
                ? {
                    personId: assistantCoach1.personId,
                    active: assistantCoach1.active,
                  }
                : { personId: "", active: false },
              manager: managerPerson
                ? {
                    personId: managerPerson.personId,
                    active: managerPerson.active,
                  }
                : { personId: "", active: false },
              physiotherapist: physiotherapistPerson
                ? {
                    personId: physiotherapistPerson.personId,
                    active: physiotherapistPerson.active,
                  }
                : { personId: "", active: false },
              doctor: doctorPerson
                ? {
                    personId: doctorPerson.personId,
                    active: doctorPerson.active,
                  }
                : { personId: "", active: false },
              other: otherPerson
                ? {
                    personId: otherPerson.personId,
                    active: otherPerson.active,
                  }
                : { personId: "", active: false },
            };
          } else if (sport === "handball") {
            team.staffState = {
              coach_head: headCoachPerson
                ? {
                    personId: headCoachPerson.personId,
                    active: headCoachPerson.active,
                  }
                : { personId: "", active: false },
              assistantCoach1: assistantCoach1
                ? {
                    personId: assistantCoach1.personId,
                    active: assistantCoach1.active,
                  }
                : { personId: "", active: false },
              assistantCoach2: assistantCoach2
                ? {
                    personId: assistantCoach2.personId,
                    active: assistantCoach2.active,
                  }
                : { personId: "", active: false },
            };
          } else {
            team.staffState = {
              coach: coachPerson
                ? {
                    personId: coachPerson.personId,
                    active: coachPerson.active,
                  }
                : { personId: "", active: false },
            };
          }
        }

        newTeamsData[teamIndex] = team;
      });
      return newTeamsData;
    }
    return [];
  };

  const updateTeam = useCallback(
    (newTeamsData) => {
      setTeamsData((oldTeams) =>
        oldTeams.map((team) => (team.entityId === newTeamsData.entityId ? newTeamsData : team)),
      );
      let tempEntities = currentState.entities;
      if (tempEntities) {
        let entIndex = tempEntities.findIndex((ent) => ent.entityId === newTeamsData.entityId);
        if (entIndex > -1) {
          tempEntities[entIndex].primaryColor = newTeamsData.primaryColor;
        }
      }
    },
    [currentState.entities],
  );

  useEffect(() => {
    const newValidation = {};
    teamsData.forEach((team) => {
      newValidation[team.entityId] = getValidationObject(team, fixtureProfile, sport);
    });
    setValidationInfo(newValidation);

    if (teamsData.length > 0) {
      localStorage.setItem(match.params.fixtureId + "-teams", JSON.stringify(teamsData));
    }
  }, [teamsData, match, fixtureProfile, sport]);

  useEffect(() => {
    setTeamsData(buildNewTeamsData());
    //TODO: fix once MQTT state can be properly tracked
    // eslint-disable-next-line
  }, [JSON.stringify(currentState.mqtt?.entities)]);

  useEffect(() => {
    if (currentState.mqtt?.entities) {
      teamsData.forEach((team) => {
        const competitor = competitors.find((comp) => comp.entityId === team.entityId);

        if (!competitor.rosters.fixture?.length) {
          return;
        }

        const storageKey = buildTeamManagerSyncStorageKey(match.params.fixtureId, team.entityId);

        if (window.localStorage.getItem(storageKey)) {
          return;
        }

        team.persons.forEach((person) => {
          sendPlayer(person, team, currentState.mqtt, match.params.fixtureId, "added", sport);
        });

        window.localStorage.setItem(storageKey, true);
      });
    }
  }, [teamsData, currentState.mqtt?.entities]);

  useEffect(() => {
    const organizationId = organization?.organizationId;
    if (organizationId) {
      updateState("orgnizationId", organizationId);
      sessionStorage.setItem("orgId", organizationId);
    }
    if (competitors) {
      setTeamsData(buildNewTeamsData());
      setIsReadyToShow(true);
    }
    //TODO: updateState triggers constant re-renders, remove eslint disable once that's handled
    // eslint-disable-next-line
  }, [competitors]);

  const teamsDisplay = useMemo(() => {
    return teamsData.map((competitor, index) => {
      return <Team key={`team_info_${index}`} data={competitor} updateTeam={updateTeam} currentState={currentState} />;
    });
  }, [teamsData, currentState, updateTeam]);

  const titles = useMemo(() => {
    const participants = teamsData.filter((team) => !!team.nameFullLocal);
    const participantsTitle = participants.map((it) => it.nameFullLocal).join(" v. ");
    const mainTitleBase = formatMessage({
      id: "setup",
      defaultMessage: "Team Setup",
    });
    const main = [participantsTitle, mainTitleBase].filter((e) => !!e).join(" - ");
    const page = `${main} - ${title}`;
    return { main, page };
  }, [teamsData, title, formatMessage]);

  return (
    <Container className="setup-page">
      <PageDisplay
        title={titles.main}
        pageTitle={titles.page}
        ref={pageRef}
        error={errorMsg}
        loading={!hasLoaded}
        history={history}
        reload={forceRefresh}
      >
        {!isReadyToShow && (
          <div className="loading-container">
            <Spinner size="md" color="orange" />
          </div>
        )}
        {isReadyToShow && !errorMsg && (
          <>
            {sport === "handball" && <FreshChat />}
            <div className="teams-container">{teamsDisplay}</div>
            <TeamSetupControls validationInfo={validationInfo} />
          </>
        )}
      </PageDisplay>
    </Container>
  );
};

export default withRouter(injectIntl(TeamSetup));
