import React, { useContext } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import { formatPlayerShortenedName } from "../../../Base/Utilities/Player";

const SHARED_FILTERS = {
  flagged: "flagged",
};
const EMPTY_VALUE = -1;

const RESET_EVENT = { target: { value: EMPTY_VALUE } };

function getEventTypesBySport(sport) {
  if (sport === "hockey") {
    return {
      ...SHARED_FILTERS,
      scores: "goals",
      fouls: "cards",
    };
  }

  if (sport === "handball") {
    return {
      ...SHARED_FILTERS,
      scores: "goals",
      sevenMetrePenalty: "sevenMetrePenalty",
      substitution: "substitution",
      sanctions: "sanctions",
    };
  }

  if (sport === "basketball") {
    return {
      ...SHARED_FILTERS,
      scores: "scores",
      fouls: "fouls",
      block: "block",
      rebound: "rebound",
      steal: "steal",
      turnover: "turnover",
    };
  }

  return {
    ...SHARED_FILTERS,
    scores: "scores",
    fouls: "fouls",
  };
}

const EventLogFilter = (props) => {
  const {
    periods,
    intl,
    clearFilters,
    filterValues,
    filterActive,
    setFilter,
    personList,
  } = props;
  const { formatMessage } = intl;
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);
  const filterOptions = getEventTypesBySport(sport);

  const onFilterChange = (ev) => {
    const { value } = ev.target;

    if (value === SHARED_FILTERS.flagged) {
      setFilter("eventType", RESET_EVENT);
      setFilter("flagged", ev);
    } else if (value === EMPTY_VALUE) {
      setFilter("eventType", RESET_EVENT);
      setFilter("flagged", RESET_EVENT);
    } else {
      setFilter("flagged", RESET_EVENT);
      setFilter("eventType", ev);
    }
  };

  const renderPeriodLabelText = (periodId) => {
    if (periodId === "All") {
      return formatMessage({
        id: "filter.all",
        defaultMessage: "All",
      });
    }

    if (periodId >= fixtureProfile.initialExtraPeriodId) {
      if (["hockey", "handball"].includes(sport)) {
        return formatMessage({
          id: "period.overtime.shootout.abbreviation",
          defaultMessage: "SO",
        });
      }

      return (
        formatMessage({
          id: "period.overtime.abbreviation",
          defaultMessage: "OT",
        }) +
        (periodId - (fixtureProfile.initialExtraPeriodId - 1))
      );
    }

    return (
      formatMessage({
        id: "period.abbreviation",
        defaultMessage: "P",
      }) + periodId
    );
  };

  return (
    <div className={"event-log-filters filtered-" + filterActive}>
      {filterActive && (
        <span className="clear-filters" onClick={() => clearFilters()}>
          <i className="fas fa-filter"></i>
          <i className="fas fa-times"></i>
          {/* {formatMessage({
            id: "clear",
            defaultMessage: "Clear",
          })} */}
        </span>
      )}
      <select
        className="period-filter"
        onChange={(e) => setFilter("period", e)}
        value={filterValues.period}
      >
        {periods.map((period) => {
          return (
            <option value={period} key={period}>
              {renderPeriodLabelText(period)}
            </option>
          );
        })}
        {sport === "handball" && (
          <option value="All">
            {formatMessage({
              id: "filter.all",
              defaultMessage: "All",
            })}
          </option>
        )}
      </select>
      <select
        className="type-filter"
        onChange={(e) => onFilterChange(e)}
        value={
          filterValues.eventType === EMPTY_VALUE
            ? filterValues.flagged
            : filterValues.eventType
        }
      >
        <option value={EMPTY_VALUE}>
          {formatMessage({
            id: "filter.all",
            defaultMessage: "All",
          })}
        </option>
        {Object.keys(filterOptions).map((type, idx) => {
          return (
            <option value={type} key={`${type}-${idx}`}>
              {formatMessage({
                id: "filter." + filterOptions[type],
                defaultMessage: type,
              })}
            </option>
          );
        })}
      </select>
      <select
        className="person-filter"
        onChange={(e) => setFilter("personId", e)}
        value={filterValues.personId}
      >
        <option value={parseInt(-1)} key={-1}>
          {formatMessage({
            id: "filter.all",
            defaultMessage: "All",
          })}
        </option>
        {personList.map((person, idx) => {
          return (
            <option value={person.personId} key={`${person.personId}-${idx}`}>
              {person.bib}. {formatPlayerShortenedName(person.name)}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default injectIntl(EventLogFilter);
